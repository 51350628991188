import './loginStyle.css'
import axios from '../router/ApiService'
import alert from '../Alerter'

const model = {
    emailValue: '',
    passValue: '',
    activeButton: 0,
    btn_color: '#17E8F3'
};

export default {
    data: () => (model),
    computed: {
        validEmail() {
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailValue)
        }
    },
    methods: {
        async onClickButton() {
            if (model.activeButton === 0 && (model.emailValue !== undefined && model.emailValue !== '') && (model.passValue !== undefined && model.passValue !== '')) {
                model.activeButton = 1
                await this.callApiLogin();
            } else {
                model.activeButton = 0
                alert.openNotification(this.$vs, 'Error', 'Email or pass is Empty', 'danger')
            }
        },
        async callApiLogin() {

            const form = new URLSearchParams();

            form.append('email', this.emailValue);
            form.append('password', this.passValue);

            await axios().post('auth/login', form)
                .then((res) => {
                    sessionStorage.token = res.data.message
                    this.$store.dispatch('incrementToken', sessionStorage.token)
                    this.activeButton = 0;
                    this.$router.push('/main');
                }).catch((e) => {
                    alert.openNotification(this.$vs, 'Error', e.message, 'danger')
                    console.log(e.message);
                    this.activeButton = 0;
                })

        }
    },
    name: "login"
}
